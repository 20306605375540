<template>
	<div class="section_container theme_join fix_min_heihgt">
		<div class="section_header">
			<h1 class="logo">
				<a href="https://www.daouoffice.com/">
					<img src="@/assets/images/logo_do.png" alt="다우오피스" title="다우오피스" />
				</a>
				<span class="txt">다우오피스 멤버십</span>
			</h1>
			<div class="snb">
				<h2 class="logo">
					<img src="@/assets/images/logo_daou_220519.png" alt="다우기술" title="다우기술" />
				</h2>
			</div>
		</div>
		<div class="">
			<router-view />
		</div>
	</div>
</template>

<script>
export default {
	components: {
		//ApplyHeader,
	},
	methods: {},
};
</script>

<style scoped>
.fix_min_heihgt {
	min-height: 960px;
}
</style>
